<template>
  <div class="wrapper" id="marketDetail">
    <ctheader></ctheader>
    <div class="content">
      <div class="base-info">
        <div class="top">
          <span class="top-title">{{ data.name }}</span>
          <span>{{ data.statusName }}</span>
        </div>
        <div class="info">
          <div class="field">
            <label>负责人：</label>
            <span>{{ data.contact }}</span>
          </div>
          <div class="field">
            <label>联系方式：</label>
            <span>{{ data.tel }}</span>
          </div>
          <div class="field">
            <label>过期时间：</label>
            <span>{{ data.expiration_date }}</span>
          </div>
          <div class="field">
            <label>市场数量：</label>
            <span>{{ data.market_num }}</span>
          </div>
        </div>
      </div>
      <div class="sub-title">基础信息初始化</div>
      <div class="funs">
        <div
          class="fun"
          v-for="(fun, index) in funs"
          :key="index"
          @click="toFun(fun.url)"
        >
          <span>{{ fun.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @module app/customer/customerDetail
 * @desc 传蔬农贸版 客户详情
 * @version 0.0.1
 * @author songkexin <songkexin@rongyi.com>
 * @date   2021-08-21
 * @copyright 2021
 */
import { mapGetters, mapActions } from "vuex";
import { apiPost } from "../../modules/utils";
// import ctheader from '@@/ctheader';
import ctheader from "../../components/ctheader";
import layer from "../../components/layers";
// import layer from '@@/layers';
import * as URL from "../../modules/URLs";
import APIs from "../../modules/APIs";
// import router from '@/router';
import router from "../../router";
const getters = mapGetters(["sideMenu"]);
const actions = mapActions(["setAlert"]);
export default {
  name: "marketdetail",
  computed: {
    ...getters,
  },
  components: { ctheader, layer },
  beforeRouteEnter(to, from, next) {
    let id = to.params.id;
    let code = to.query.code;
    // this.id = to.params.id;
    // this.code = to.params.code;
    let getInfo = new Promise(function(resolve, reject) {
      apiPost({
        url: APIs.COMPANY,
        data: {
          action: "company.detail",
          company_id: id,
        },
        success(res) {
          resolve(res);
        },
        error(res) {
          reject(res);
        },
      });
    });
    getInfo
      .then((res) => {
        next((vm) => {
          vm.id = id;
          vm.data = res || {};
          vm.data.statusName =
            vm.is_official === 1
              ? "已购买"
              : vm.is_official === 2
              ? "测试"
              : "试用";
          vm.funs[0].url =
            URL.CATEGORY + "/" + vm.id + "?code=" + code + "&id=" + id;
          vm.funs[1].url =
            URL.COST_TYPE + "/" + vm.id + "?code=" + code + "&id=" + id;
          vm.funs[2].url =
            URL.DIYMENU + "/" + vm.id + "?code=" + code + "&id=" + id;
          // if(res.code === '0'){
          // 	vm.data = res;
          // 	vm.funs[0].url = URL.CATEGORY + '/' + vm.id;
          // 	vm.funs[1].url = URL.COST_TYPE + '/' + vm.id;
          // }
          // else{
          // 	vm.data = {};
          // }
        });
      })
      .catch((res) => {
        next();
      });
  },
  data() {
    return {
      id: "", // 市场id
      code: "",
      data: {},
      funs: [
        {
          name: "商品品类管理",
          icon: "sdnm-commodity_4",
          show: true,
          url: "",
          msgNum: "",
          showMSg: false,
        },
        {
          name: "费用类型管理",
          icon: "sdnm-charge_bill",
          url: "",
          show: true,
          msgNum: "",
          showMSg: false,
        },
        {
          name: "自定义菜单",
          icon: "sdnm-charge_bill",
          url: "",
          show: true,
          msgNum: "",
          showMSg: false,
        },
      ], // 功能块信息
    };
  },
  methods: {
    ...actions,
    /**
     * 设置跳转
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-08-21
     */
    toFun(path) {
      console.log(path);
      console.log(URL.DIYMENU + "/" + this.id + "?code=" + this.code);
      // router.push(path);

      router.push(path);
      // this.$router.push(URL.DIYMENU + "/" + 136 + "?code=" + 200125);
    },
  },
};
</script>

<style lang="less">
@import url("../../less/common.less");
#marketDetail {
  .mgl20 {
    .mgl(32px) !important;
    .pos-r();
    &:after {
      content: "";
      .wh(1px, 8px);
      position: absolute;
      right: 70px;
      top: 2px;
      .bgc(#aeb5bf);
    }
  }
}
#marketDetail .content {
  background-color: #eceff3;
  box-shadow: none;
  overflow-y: auto;
  .pd(0);
  font-size: 0;
}
#marketDetail .base-info {
  height: 150px;
  width: 100%;
  .dib();
  .bgc(#ffffff);
  box-shadow: 0 0 10px 0 #e0e3e8;
  .brdr(4px);
  .pd(24px);
  .pos-r();
  .top {
    .mgt(10px);
    .mgb(20px);
    span {
      display: inline-block;
      .fc(12px, #aeb5bf);
      &.top-title {
        .fc(18px, #253650);
        margin-right: 15px;
      }
    }
    div {
      .fc(12px, #253650);
    }
  }
  .info {
    .field {
      .dib();
      width: 25%;
      .mgb(10px);
      .hlh(15px, 15px);
      > label {
        .fc(12px, #aeb5bf);
      }
      > span {
        .fc(12px, #253650);
      }
    }
  }
}
#marketDetail .sub-title {
  .fc(18px, #a0b9db);
  .mgt(30px);
  .mgb(16px);
}
#marketDetail .funs {
  .fun {
    .dib();
    .wh(180px, 120px);
    .bgc(#ffffff);
    .brdr(4px);
    .ta-c();
    .mgr(16px);
    .mgb(25px);
    .cs();
    .pos-r();
    > i {
      .fc(40px, #a0b9db);
      .db();
      .mgt(27px);
      .mgb(9px);
    }
    > span {
      .fc(16px, #525e6e);
      line-height: 120px;
    }
    &:hover {
      .bgc(#257873);
      > i,
      > span {
        color: #ffffff;
      }
    }
    > div {
      position: absolute;
      height: 16px;
      .pd(1px, 5px, 1px, 4px);
      .bgc(#f84445);
      .fc(10px, #ffffff);
      .brdr(4px);
      right: 59px;
      top: 19px;
      line-height: 14px;
      -webkit-transform-origin-x: 0;
      -webkit-transform: scale(0.9);
    }
  }
}
</style>
